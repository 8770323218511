@import '../../preview-shadow.scss';

@media (min-width: 768px) {
  .pattern-header-wrapper {
    display: flex;
  }
}

.preview-wrapper {
  display: flex;
  flex: 1 1 auto;
  max-width: 100%;
  align-items: center;
  justify-items: center;
  align-content: center;
  justify-content: center;

  img {
    @extend .preview-shadow-box;
  }
}

.pattern-description {
  margin-top: 12px;
}

.pattern-detail-wrapper {
  display: flex;
  flex-flow: column;
  word-break: break-word;

  .pattern-name-header {
    margin-bottom: 25px;
  }

  .see-history-link {
    font-size: 0.9em;
  }

  .pattern-tags-wrapper {
    display: flex;
    margin-bottom: 20px;

    & > div {
      flex: 1;
    }
  }

  .pattern-buttons {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .download-button {
      width: 200px;
    }
  }

  .corrupted-creative {
    background-color: #ff9966;
    font-size: 150%;
    padding: 10px;
  }
}

.detail-header-title-row {
  display: flex;
  align-items: center;
}

.detail-header-buttons {
  display: flex;
  align-items: center;

  .pattern-button-lock {
    cursor: pointer;
    margin-right: 12px;
  }
}

.pattern-preview-image {
  max-width: 100%;
  max-height: 100%;
  background: repeating-conic-gradient(#e0e0e0 0% 25%, transparent 0% 50%) 50% / 20px 20px;
}

.inline-edit-error {
  text-decoration: line-through;
  color: red;
}
