.destinations-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  span.tag {
    width: fit-content;
    max-width: 350px;
    margin-right: .4em;
    display: block;
    word-wrap: break-word;
    white-space: normal;
  }

  .autosuggest-wrapper {
    margin-top: 0;
  }
}

.document-properties-list {
  display: flex;
  flex-direction: column;
}

button.inline-edit-button-action {
  display: inline-block;
  padding-top: 7px;
  padding-bottom: 7px;

  margin-left: 10px;
}

button.edit-button {
  padding: 6px;
}

a.tag-link:hover {
  text-decoration: none;
}

a.tag-link span.tag:hover {
  background-color: #b0d9e6;
}

span.no-services-tag {
  background-color: #cbcecf ;
}
