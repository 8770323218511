.file-list {
  display: grid;
  width: 100%;
  margin: auto;
  grid-template-columns: repeat( auto-fill, minmax(300px, 1fr));
  grid-gap: 10px;

  &.file-list-single {
    width: 50%;
    grid-template-columns: 1fr;
  }

  .list-group-item.file-panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 24px;
    padding-bottom: 24px;
    overflow: hidden;
    margin-bottom: 10px;

    .remove-icon {
      font-size: 1.5em;
      color: #c70000;
      position: absolute;
      top: 8px;
      right: 12px;
    }

    span {
      margin-bottom: 8px;
    }
  }
}

div.upload-button-panel {
  margin: 10px auto;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upload-preview-content {
  display: flex;
  flex-direction: column;
  align-items: center;

  i.fa-image {
    height: 150px;
  }

  img {
    height: 150px;
  }
}
