.autosuggest-wrapper {
  display: flex;
  margin-top: 12px;

  input.react-autosuggest__input {
    border: 1px solid #c4cdd6;
    padding: 5px 8px;

    &.valid {
      border-right: none;
    }
  }

  &.autosuggest-longer {
    input.react-autosuggest__input {
      width: 250px;
    }
  }

  button {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 28px;
  max-width: 300px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 13px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  max-height: 340px;
  overflow-y: auto;
  z-index: 2;
}

.autosuggest-longer {
  .react-autosuggest__suggestions-container--open {
    max-width: 400px;
  }
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 5px 10px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}
