h3.card-combo-title {
  margin-top: 0;
  padding-top: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: right;
}

.card-combo>div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .asset-preview {
    max-height: 200px;
    width: 100%;
    min-width: 350px;
    margin: 0;
    padding: 0;
  }
}

button.btn.combo-color-swatch {
  margin: 4px;
  height: 24px;
  width: 24px;
  padding: 12px;
  display: inline-block;
  border: none;
  box-shadow: 0 0 4px rgb(0 0 0 / 50%);

  &:hover {
    border: none;
  }

  &:focus {
    outline: 0px;
  }
}

.copy-color-swatch {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
