.publishedToggle {
  margin-left: auto;

  .toggle {
    width: auto;
    margin-left: .5em;

    .toggle-label {
      width: 106px;
    }
  }
}
