@import '../../preview-shadow.scss';

.assets-list-grid {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-gap: 15px;

  .card {
    .card-block {
      a {
        display: flex;
        flex-direction: column;
        height: 100%;

        .preview-wrapper {
          height: 200px;
          max-height: 200px;
          display: flex;
          flex: 1 1 auto;
          justify-content: center;
          align-items: center;
          justify-items: center;

          .cams-no-preview {
            height: 100px;
            padding: 0px 10px;
          }

          * {
            @extend .preview-shadow-box
          }

          &.preview-wrapper-larger {
            height: 350px;
            max-height: 350px;
          }
        }

        p {
          color: gray;
          font-size: .9em;
          margin-bottom: 0;
          margin-top: 12px;
        }

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

// medium - 2 columns
@media (min-width: 992px) {
  .assets-list-grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

// large - 3 columns
@media (min-width: 1200px) {
  .assets-list-grid {
    grid-gap: 20px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
