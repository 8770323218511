.tags-form {
  display: flex;
  flex-wrap: wrap;
  max-height: 250px;
  overflow-y: auto;
  overflow-x: hidden;  

  .tag {
    margin: 5px 5px 0 0;
    cursor: pointer;
  }

  .selected .tag {
    color: #fff;
    background-color: #0088a9;

    button {
      color: #fff;
    }
  }
}