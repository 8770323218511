.dc-assets-list-grid {
  .card .card-block h2 {
    margin-top: -5px;
  }

  .dc-asset-tile-header {
    display: flex;

    span {
      margin-left: auto;
    }
  }
}

// small - 2 columns
@media (min-width: 600px) {
  .dc-assets-list-grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

// medium - 3 columns
@media (min-width: 992px) {
  .dc-assets-list-grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

// large - 3 columns
@media (min-width: 1200px) {
  .dc-assets-list-grid {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}
