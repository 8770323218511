.assets-list-page {
  padding-bottom: 180px;

  .assets-page-bar {
    display: flex;
    align-items: center;
    margin-top: 16px;

    a.btn, button.btn {
      margin-left: 16px;
    }
  }
}

.asset-type-header {
  margin-bottom: 20px;
}
