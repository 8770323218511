.category-localization-section {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .download-button {
    margin-right: 6px;
  }
}

.category-localization-table-wrapper {
  overflow-x: auto;
}

.category-localization-table {
  th {
    padding: 12px 12px 12px 0;
    white-space: nowrap;
  }

  td {
    padding: 12px 12px 12px 0;
  }
}
