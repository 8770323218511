div.alert-warning-no-assets-found {
  float: left;
  margin: 0 0 20px 0;
}

.composed-sample-container {
  margin-top: 20px;

  h2 {
    margin-top: 0;
  }
}

.alert-container {
  margin: 20px 0 0 0;
  display: flex;
  background-color: white;
}

.composed-sample-list {
  display: flex;
  justify-content: space-evenly;
  padding: 20px;
  flex-wrap: wrap;
  gap: 20px;
}
