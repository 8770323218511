.assets-list-paging {
  display: flex;
  align-items: flex-start;
  margin-right: 12px;

  form {
    margin: 18px 12px 0 0;

    input.form-control {
      width: 70px;
    }
  }

  .react-select-wrapper {
    margin: 18px 0 0 12px;
  }
}
