.asset-kind-selector-header {
  margin: 0 0 8px 0;

  button.btn.dropdown-toggle {
    border: none !important;
    color: #000 !important;
    font-weight: normal;
    background: transparent !important;
  }

  span.caret {
    vertical-align: top;
    font-size:24px;
  }
}

button.dropdown-toggle {
  &:disabled {
    span.caret {
      display: none;
    }
    opacity: 1;
    margin-bottom: 4px;
   }
}

.asset-kind-selector-header button.btn {
  padding-left: 5px;
}

.asset-kind-selector-header-title {
  margin: 0px;
  color: #000;
  display: inline-block;
}
