.template-preview-image {
  max-width: 100%;
  max-height: 100%;
  background: repeating-conic-gradient(#e0e0e0 0% 25%, transparent 0% 50%) 50% / 20px 20px;
  border: 1px solid lightgray;
}

div.spinner-container {
  max-width: 100px;
  position:absolute;
  bottom: 5px;
  right:7px;
  display:inline-block;
  box-shadow: none !important;
}

.spinner-container * {
  box-shadow: none !important;
}

.spinner-container:hover * {
  box-shadow: none !important;
}
