.detail-header {
  display: flex;
  justify-content: space-between;
  justify-items: center;
  margin-bottom: 15px;
}

.background-upload-new-version-button {
  width: 200px;
}

button.upload-button {
  width: 200px;
}

button.select-button {
  width: 200px;
  margin-bottom: 30px;
}
