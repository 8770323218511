@media (min-width: 768px) {
  .header-wrapper {
    display: flex;
  }
}

.detail-header-description {
  margin-top: 12px;
}

.detail-header-wrapper {
  display: flex;
  flex-flow: column;
  word-break: break-word;

  .detail-header-name {
    margin-bottom: 25px;
  }

  .see-history-link {
    font-size: .9em;
  }

  .detail-header-tags-wrapper {
    display: flex;
    margin-bottom: 20px;

    & > div {
      flex: 1;
    }
  }

  .detail-header-buttons {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .download-button {
      width: 200px;
    }
  }

  .pattern-buttons {
    display: flex;
    margin-left: auto;

    .download-button {
      width: 200px;
    }
  }

  .preview-buttons {
    display: flex;
    margin-left: auto;
    justify-content: flex-end;

    .download-button {
      width: 200px;
    }
  }
}


.detail-header-title-row {
  display: flex;
  align-items: center;
}

.detail-preview-image {
  max-width: 100%;
  max-height: 100%;
  background: repeating-conic-gradient(#e0e0e0 0% 25%, transparent 0% 50%) 50% / 20px 20px;
}
