@import '../../preview-shadow.scss';

@media (min-width: 768px) {
  .design-header-wrapper {
    display: flex;
  }
}

.preview-wrapper {
  display: flex;
  flex: 1 1 auto;
  max-width: 100%;
  align-items: center;
  justify-items: center;
  align-content: center;
  justify-content: center;

  img {
    @extend .preview-shadow-box;
  }
}

.design-detail-wrapper {
  display: flex;
  flex-flow: column;

  .design-name-header {
    margin-bottom: 25px;
    word-wrap: break-word;
  }

  .see-history-link {
    font-size: .9em;
  }

  .design-description {
    flex: 1;  /* 1 and it will fill whole space left */
  }

  .design-buttons {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .download-button {
      width: 200px;
    }

    .buttons-wrapper {
      display: flex;
    }

    .download-with-linked-assets {
      margin-left: 10px;
      width: auto;
    }
  }
}

.detail-header-buttons {
  display: flex;
  align-items: center;

  .design-button-lock {
    cursor: pointer;
    margin-right: 12px;
  }
}

.corrupted-design {
  background-color: #ff9966;
  font-size: 150%;
  padding: 10px;
}

.fa-exclamation-triangle {
  margin-right: 5px;
}
