.unsaved-changes-warning {
  margin-left: 1em;
  display: inline-block;
}

.section-title {
  margin-top: 9px;
  margin-bottom: 9px;
}

div.detail-header {
  margin-bottom: 0px;

  .asset-kind-selector-header {
    margin: 0px;
  }
}

.category-ordering-section .container {
  width: auto;
}
