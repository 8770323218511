div.upload-zone {
  min-height: 250px;
  display: flex;
  background-color: #f0f0f0;
  border: 2px dashed #979797;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .selected-files-for-upload {
    display: flex;

    div {
      background-color: white;
      border: 1px solid gray;
    }
  }
}

div.upload-zone-smaller {
  min-height: auto;
}

.background-text {
  text-align: center;
  color: #ccc;
}
