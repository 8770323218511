.asset-large-tile {
  .checkbox {
    opacity: 0;
    color: grey;
  }

  .checkbox.checkbox-checked {
    opacity: 1;
  }

  &:hover .checkbox {
    opacity: 1;
  }
}
