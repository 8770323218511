.color-group-swatches {
  text-align: center;

  .color-swatch-dot {
    margin: 4px 4px 16px 10px;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    display: inline-block;

    box-shadow: 0 0 3px rgba(0,0,0,0.3);

    &.active {
      transform: scale(1.2);
    }
  }
}

.panel-preview-picker {
  &.panel-preview-picker-active div {
    border-bottom: 4px solid #C4CDD6;
  }

  div {
    border-bottom: 4px solid #F1F1F1;
  }
}
