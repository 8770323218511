.design-concept-input {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  .child:first-child {
    flex: 1;
  }

  .child:last-child {
    width: 100%;
  }
}
