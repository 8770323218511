div.list-view-switch {
  display: block;

  @media (min-width: 992px) and (max-width: 1340px) {
    width: 140px;
  }

  @media (max-width: 676px) {
    width: 140px;
  }

  button.btn {
    border-radius: 0;
    border: 0;
    padding: 15px 15px 15px 20px;

    i.button-icon {
      font-size: 1.4em;
    }

    span {
      margin-left: 8px;
    }
  }
}
