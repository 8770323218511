.color-input {
  height: 47px;
}

.color-preview {
   width: 47px;
   border: 1px solid lightgray;
}

.color-name {
  margin-top:15px;
}

.border-red {
  border:1px solid red;
}
