.design-concepts-table {
  margin: 20px;
}

.design-concepts-filters {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .asset-search-form {
    margin-right: 15px;
  }
}

.design-concepts-filters-switch {
  display: block;
  margin-right: 15px;
  margin-bottom: 15px;

  button.btn {
    border-radius: 0;
    border: 0;
    padding: 14px 14px 14px 20px;

    span {
      margin-left: 8px;
    }
  }
}
