.asset-preview {
  flex: 1;
  margin-right: 20px;
  height: 200px;
  width: 250px;
  background-color: white;
  align-items: center;
  justify-items:center;
  align-content: center;
  justify-content: center;
  display:flex;

  * {
    @extend .preview-shadow-box;
  }
}

.asset-detail {
  display: flex;
  flex: 2;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}
