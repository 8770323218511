// COPIED FROM node_modules/react-simple-tree-menu/dist/main.css
// AND UPDATED

.collections-form {
  padding-left: 1em;
  white-space: nowrap;
  overflow-y: auto;
  overflow-x: hidden;
  text-overflow: ellipsis;
  max-height: 250px;
  position: relative;
}

.rstm-toggle-icon {
  display: inline-block;

  .rstm-toggle-icon-symbol {
    width: 2rem;
    height: 2rem;
    text-align: left;
    line-height: 2rem;
    color: #0088a9;
  }
}

.rstm-tree-item-group {
  ul {
    list-style-type: none;
    padding-left: 0;
    text-align: left;
    width: 100%;
  }

  .rstm-tree-item, .rstm-tree-item-all {
    padding: 0.75rem 1rem;
    cursor: pointer;
    color: #333;
    background: none;
    z-index: unset;
    position: relative;

    &.rstm-tree-item--active {
      color: #fff;
      background: #0088a9;

      .rstm-toggle-icon-symbol {
        color: #fff;
      }
    }
    &.rstm-tree-item--focused {
      border: 1px solid #c4cdd6;
      z-index: 999;
    }
  }

  .rstm-tree-item-all {
    padding-left: 2.75rem;
  }
}

.rstm-search {
  padding: 1rem 1.5rem;
  border: none;
  width: 100%;
}
