.filters-strip {
  margin-bottom: 20px;
}

span.tag-with-space {
  margin-left: 5px;
  margin-bottom: 5px;
  padding-left: 9px;
  padding-right: 4px;
  padding-top: 0px;
  padding-bottom: 0px;

  button.close {
    font-size: 12px;
    padding: 5px;
    margin-left:0px;
  }
}
