.asset-search-form {
  display: flex;
}

.asset-search-term-input {
  flex-grow: 1;
}

.asset-search-exact-checkbox {
  margin: -10px 0 16px 16px !important;

}

.asset-search-exact-checkbox label {
  margin: 0 0 5px 0 !important;
  top: 0 !important;
  left: 0 !important;
  max-height: 48px !important;
  max-width: none !important;
  overflow: hidden !important;
  vertical-align: top !important;
}
