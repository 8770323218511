@import '../preview-shadow.scss';

.max-width-auto {
  width: auto !important;
}

.preview-column img {
  @extend .preview-shadow-box;
  max-width: 20vw;
  max-height: 90vh;
  object-fit: contain;
}

.preview-column {
  display: flex;
  justify-content: center;
}

.flex-column-align-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
