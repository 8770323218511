body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

// https://stackoverflow.com/a/62103854/5558570
*,*:focus,*:hover{
    outline:none;
}

.container-fluid {
  padding-bottom: 60px;
}

.section {
  margin-bottom: 24px;
}

@media (min-width: 1450px) {
  .container-fluid.container-full-width {
    padding-left: 4%;
    padding-right: 4%;
  }
}

.container-stretch {
  max-width: 1300px;
}

div.row.cams-page-title,
h2.section-title {
  line-height: 48px;
}

.card-grid {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fit, 300px);
  white-space: nowrap;
}

h3.card-title {
  color: #319EBA;
  margin-bottom: 10px;
  margin-top: 8px;
  word-wrap: break-word;
  line-height: 1.3em;
}

.cams-no-preview, .cams-no-items {
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-text {
  line-height: 1.6em;
  margin-bottom: 10px;
}

/* Custom extension to UI library */

.accordion.cams-accordion-card {
  background-color: #fff;
  border: 1px solid #c4cdd6;
  border-radius: 2px;
  box-shadow: 0 1px 2px 0 rgba(0,0,0,.1);

  .accordion-header {
    display: flex;
    background-color: #fff;
    border-bottom: 1px solid #c4cdd6;
  }

  .accordion-body {
    padding: 16px;
  }
}

.simple-link {
  color: gray;
  text-decoration: underline;

  &:hover, &:visited {
    color: gray;
  }
}

table.table.simple-table tbody tr td {
  vertical-align: middle;
}

table.table.simple-table th {
  vertical-align: middle;
  text-align: center;
}

.simple-table .rt-tbody .rt-tr .rt-td {
  display: flex;
  align-items: center;
}

.cursor-pointer {
  cursor: pointer;
}

.no-tenant-message {
  display: flex;
  justify-content: center;
}

.modal-dialog {
  word-break: break-word;
}

.inline-edit-wrapper {
 display: flex;
 align-items: baseline;
 margin: -10px 0
}

 /* Bug fixes from react cimpress UI library */

.robot-figure {
  display: none !important;
}

.robot-bg {
  display: none !important;
}

button.btn i.button-icon {
  padding-right: 0;
}

.navbar .nav-tabs > li > button {
  padding: 16px 20px;
  margin: 0;
  color: #0088a9;
}
