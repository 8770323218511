@import '../preview-shadow.scss';
@import '../AssetCard.scss';

.asset-card-style {
  margin-bottom: 20px;

  .card-block {
    display: flex;
  }
}

.asset-detail {
  display: flex;
  flex: 2;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

div.asset-additional-info p {
  margin-bottom: 10px;

  .asset-additional-info-property {
    font-weight: bold;
    font-variant: small-caps;
    color: gray;
    font-size: small;
  }
}

.template-preview {
  border: 1px solid rgba(255,255,255,0);
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.template-preview:hover {
  border: 1px dotted #0092b5;
}
